import React, { useEffect, useState } from "react";
import { Link, withRouter, useHistory } from "react-router-dom";
import classnames from "classnames";
import Validator from "validator";
import { Stats } from "./Stats";
import { getCountries, getStates, getCities } from "../../actions/countriesActions";
import materialize from "materialize-css";
import { getZipCode } from "../../actions/zipcodeActions";
import Localization from "../localization/localization"
//import { DatePicker } from "react-materialize";
import moment from"moment";
import { registerAttendeeNew } from "../../actions/attendeeActions";



export const CimejalForm = (props) => {

  const [form, setform] = useState({});

  const [estados, setEstados] = useState("");
  const [ciudades, setCiudades] = useState("");  
  const [state, setState] = useState({
    form: {
        nombres: "",
        apellidos: "",
        sexo: "",
        nacimiento: "",
        telefono: "",
        carrera: "",
        cargo: "",
        email: "",
        wwwEmpresa: "",

        rfc:"",
        razonSocial: "",
        nombreEmpresa: "",
        domicilio: "",
        codigoPostal: "",
        pais: "MX",
        estado: "",
        ciudad: "",
        principalActividad: "",

        empresaFechaComienzoOperaciones: "",
        empresaExporta: "",
        empresaImporta: "",
        empresaExportaPaises: "",
        empresaImportaPaises: "",
        empresaVentas: "",
        empresaCrecimiento: "",
        empresaEmpleados: "",
        empresaInclusiva: "",
        empresaSistemaPleneacion: "",
        empresaProcesosGestion: "",
        empresaSustentable: "",
        empresaTecnologiaPunta: "",
        empresaModeloNegocioVanguardia: "",
        empresaAsociaciones: "",
        empresaCertificaciones: "",
        empresaPremioReconocimiento: "",
        empresaAfiliacionCimejal: "",

        categoria: "REGISTRO",
        locale: "es",
    }
});

  const [errorNombre, setErrorNombre] = useState('');
  const [errorApellido, setErrorApellido] = useState('');
  const [errorTelefono, setErrorTelefono] = useState('');
  const [errorNacimiento, setErrorNacimiento] = useState('');
  const [errorSexo, setErrorSexo] = useState('');
  const [errorCarrera, setErrorCarrera] = useState('');
  const [errorCargo, setErrorCargo] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorwwwEmpresa, setErrorwwwEmpresa] = useState('');

  const [errorNombreEmpresa, setErrorNombreEmpresa] = useState('')
  const [errorRazonSocial, setErrorRazonSocial] = useState('');
  const [errorDomicilio, setErrorDomicilio] = useState('');
  const [errorRFC, setErrorRFC] = useState('');
  const [errorPrincipalActividad, setErrorPrincipalActividad] = useState('');
  const [errorEmpresaFechaComienzoOperaciones, setErrorEmpresaFechaComienzoOperaciones] = useState('');
  const [errorEmpresaExportaPaises, setErrorEmpresaExportaPaises] = useState('');
  const [errorEmpresaImportaPaises, setErrorEmpresaImportaPaises] = useState('');
  const [errorCodigoPostal, setErrorCodigoPostal] = useState('');
  
  const [errorEstado, setErrorEstado] = useState('');
  const [errorCiudad, setErrorCiudad] = useState('');
  const [errorEmpresaAsociaciones, setErrorEmpresaAsociaciones] = useState('');

  const [errorEmpresaExporta, setErrorEmpresaExporta] = useState('');
  const [errorEmpresaImporta, setErrorEmpresaImporta] = useState('');

  const [errorVentas, setErrorVentas] = useState('');
  const [errorCrecimiento, setErrorCrecimiento] = useState('');
  const [errorEmpleados, setErrorEmpleados] = useState('');
  const [errorInclusion, setErrorInclusion] = useState('');
  const [errorPlaneacion, setErrorPlaneacion] = useState('');
  const [errorGestion, setErrorGestion] = useState('');
  
  const [errorSustentable, setErrorSustentable] = useState('');
  const [errorTecnologia, setErrorTecnologia] = useState('');
  const [errorModeloNegocio, setErrorModeloNegocio] = useState('');
  const [errorCertificaciones, setErrorCertificaciones] = useState('');
  const [errorReconocimiento, setErrorReconocimiento] = useState('');
  const [errorCimejal, setErrorCimejal] = useState('');
  

  const locale = Localization.getLocalizationInstance("MX");
  const history = useHistory();

  const populateStates = (country) => {
    let states = [];
    getStates(country)
      .then(response => {
        states = response.data[0].states.map((state) => {
          return <option key={state} value={state}>{state}</option>
        });

        setEstados(states);
        
        var elems = document.querySelectorAll('select');
        var instances = materialize.FormSelect.init(elems);
      })
      .catch(err => console.log(err));
  };

  const populateCities = (country, state) => {
    let cities = [];
    getCities(country, state)
      .then(response => {
        console.log(response);
        cities = response.data.map((city) => {
          return <option key={city} value={city}>{city}</option>
        });

        setCiudades(cities);

        var elems = document.querySelectorAll('select');
        var instances = materialize.FormSelect.init(elems);

      })
      .catch(err => console.log(err));
  };


  useEffect(() => {

    populateStates("MX");
    
    document.addEventListener('DOMContentLoaded', function () {
      var start = document.querySelectorAll('.datepicker');
      materialize.Datepicker.init(start, {
          format: "dd/mm/yyyy",
          autoClose: true,
          onSelect: function(date) {
            updateByKey("empresaFechaComienzoOperaciones", moment(date).format('DD/MM/YYYY'));
          }
      });
    
  });
  },[]);

  const update = (e) => {

    let key  = e.target.id;
    let value = e.target.value;    
    const { form } = state;



    /*
    if (e.target.id === "pais") {
      populateStates(e.target.value);
      props.update("cities",[]);
      props.update("ciudadEmpresa","");
    }*/

    if (key === "estado") {
      populateCities(form.pais, value);      
    }

    if (key.includes("exporta"))
    {
      if (key === "exportaSi")
        value = "Si"
      else
        value = "No"

        key = "empresaExporta"
    }

    if (key.includes("importa"))
    {
      if (key === "importaSi")
        value = "Si"
      else
        value = "No"

        key = "empresaImporta"
    }

    if (key.includes("ventas")) 
    {
      if (key === "ventas1")
        value = "0 a 12’000,000";
      else
        if (key === "ventas2")
          value = "12’001,000 a 30’000,000";
        else
          value =  "30’001,000 a más"

        key = "empresaVentas"
    } 
    
    if (key.includes("crecimiento")) 
    {
      if (key === "crecimiento1")
        value = "0% a 10% ";
      else
        if (key === "crecimiento2")
          value = "11% a 20%";
        else
          value =  "Más del 20% "

        key = "empresaCrecimiento"
    }

    if (key.includes("empleados")) 
    {
      if (key === "crecimiento1")
        value = "0% a 10% ";
      else
        if (key === "crecimiento2")
          value = "11% a 20%";
        else
          value =  "Más del 20% "

        key = "empresaEmpleados"
    }


    if (key.includes("inclusion")) 
    {
      if (key === "inclusionSi")
        value = "Si";
      else
          value =  "No"

        key = "empresaInclusiva"
    }


    if (key.includes("planeacion")) 
    {
      if (key === "planeacionSi")
        value = "Si";
      else
          value =  "No"

        key = "empresaSistemaPleneacion"
    }

    if (key.includes("gestion")) 
    {
      if (key === "gestionSi")
        value = "Si";
      else
          value =  "No"

        key = "empresaProcesosGestion"
    }

    if (key.includes("sustentable")) 
    {
      if (key === "sustentableSi")
        value = "Si";
      else
          value =  "No"

        key = "empresaSustentable"
    }

    if (key.includes("tecnologia")) 
    {
      if (key === "tecnologiaSi")
        value = "Si";
      else
          value =  "No"

        key = "empresaTecnologiaPunta"
    }

    if (key.includes("vanguardia")) 
    {
      if (key === "vanguardiaSi")
        value = "Si";
      else
          value =  "No"

        key = "empresaModeloNegocioVanguardia"
    }

    if (key.includes("certificacion")) 
    {
      if (key === "certificacionSi")
        value = "Si";
      else
          value =  "No"

        key = "empresaCertificaciones"
    }

    if (key.includes("reconocimiento")) 
    {
      if (key === "reconocimientoSi")
        value = "Si";
      else
          value =  "No"

        key = "empresaPremioReconocimiento"
    }

    if (key.includes("afiliado")) 
    {
      if (key === "afiliado1")
        value = "0 a 5 años ";
      else
        if (key === "afiliado2")
          value = "6 a 10 años";
        else
          if (key === "afiliado3")
            value = "11 a 15 años";
          else
            if (key === "afiliado4")
              value = "16 a 20 años";
            else
              value = "21 a 25 años";

        key = "empresaAfiliacionCimejal"
    }

    form[key] = value;
    setState({
        ...state,
        form,
    });


    console.log(form);

  };

  const updateByKey = (key, value) => {
    const { form } = state;

    form[key] = value;
    setState({
        ...state,
        form,
    });
  }

  const submitForm = () => {

    const { form } = state;
    let valid = true;

    // Errors clean up
    setErrorNombre("");
    setErrorApellido("");
    setErrorSexo("");
    setErrorNacimiento("");
    setErrorTelefono("");
    setErrorCargo("");
    setErrorCarrera("");
    setErrorEmail("");
    setErrorwwwEmpresa("");


    setErrorRFC("");
    setErrorRazonSocial("");
    setErrorNombreEmpresa("");
    setErrorDomicilio("");
    setErrorCodigoPostal("");
    setErrorEstado("");
    setErrorCiudad("");
    setErrorPrincipalActividad("");
    setErrorEmpresaFechaComienzoOperaciones("");

    setErrorEmpresaExporta("");
    setErrorEmpresaImporta("");

    setErrorVentas("");
    setErrorCrecimiento("");
    setErrorEmpleados("");

    
    setErrorInclusion("");
    setErrorPlaneacion("");
    setErrorGestion("");
    
    setErrorEmpresaAsociaciones("");
    setErrorSustentable("");
    setErrorTecnologia("");
    setErrorModeloNegocio("");
    setErrorCertificaciones("");
    setErrorReconocimiento("");
    setErrorCimejal("");



      // Nombres
      if (Validator.isEmpty(form.nombres)) {
        setErrorNombre("Nombre es requerido");
        valid = false;
      }

      // Apellidos
      if (Validator.isEmpty(form.apellidos)) {
        setErrorApellido("Apellido es requerido");
        valid = false;
      }    

      // Género
      if (Validator.isEmpty(form.sexo)) {
        setErrorSexo("Género es requerido");
        valid = false;
      }    

      // Año de nacimiento
      if (Validator.isEmpty(form.nacimiento)) {
        setErrorNacimiento("Edad es requerido");
        valid = false;
      }
      else if (!Validator.isInt(form.nacimiento)) {
        setErrorNacimiento("Edad es requerido");
        valid = false;
      }

      // Telefono 
      if (!Validator.isEmpty(form.telefono)) {
        if (Validator.isInt(form.telefono)) {
          if (!Validator.isLength(form.telefono, { min: 10, max: 10 })) {
            setErrorTelefono("Numero de teléfono Invalido, numero esperado a 10 digitos");
            valid = false;
          }
        }
        else {
          setErrorTelefono("Numero de teléfono Invalido, numero esperado a 10 digitos");
          valid = false;
        }
      }
      else {
        setErrorTelefono("Numero de teléfono es requerido");
        valid = false;
      }

      // Carrera
      if (Validator.isEmpty(form.carrera)) {
        setErrorCarrera("Su profesión es requerida");
        valid = false;
      }

      // Cargo
      if (Validator.isEmpty(form.cargo)) {
        setErrorCargo("Su posición o cargo en la empresa en requerida");
        valid = false;
      }

      // Email checks
      if (Validator.isEmpty(form.email)) {
        setErrorEmail("Email es requerido");
        valid = false;
      } else if (!Validator.isEmail(form.email)) {
        setErrorEmail("Email es invalido");
        valid = false;
      }

      // Sitio Web Empresa    
      if (Validator.isEmpty(form.wwwEmpresa)) {
        setErrorwwwEmpresa("Sitio web de la empresa es requerido");
        valid = false;
      }


      // RFC
      if (Validator.isEmpty(form.rfc)) {
        setErrorRFC("El RFC de la empresa es requerido");
        valid = false;
      }

      // Razon Social
      if (Validator.isEmpty(form.razonSocial)) {
        setErrorRazonSocial("La razón social de la empresa es requerido");
        valid = false;
      }      

      // Nombre Empresa
      if (Validator.isEmpty(form.nombreEmpresa)) {
        setErrorNombreEmpresa("El nombre comercial de la empresa es requerido");
        valid = false;
      }

      // Domicilio
      if (Validator.isEmpty(form.estado)) {
        setErrorDomicilio("El domicilio fiscal de la empresa es requerido");
        valid = false;
      }

      // Código Postal
      if (Validator.isEmpty(form.codigoPostal)) {
        setErrorCodigoPostal("Código Postal es requerido");
        valid = false;
      } else if (form.pais === "MX" || form.pais === "US") {

        if (!Validator.isInt(form.codigoPostal)) {
          setErrorCodigoPostal("Código Postal invalido, debe ser numérico");
          valid = false;
        }
        else {
            getZipCode(form.pais, form.codigoPostal)
            .then(response => {

              if (response.data === null) {
                setErrorCodigoPostal("Código Postal invalido");
                //updateByKey("estado","");
                //updateByKey("ciudad","");
                valid = false;
              }
              /*
              else {
                updateByKey("estado", response.data.stateName);
                updateByKey("ciudad", response.data.city);
              }
              */
            });
        }        
      }

      // Estado
      if (Validator.isEmpty(form.estado)) {
        setErrorEstado("Estado es requerido");
        valid = false;
      }

      // Ciudad Empresa
      if (Validator.isEmpty(form.ciudad)) {
        setErrorCiudad("Ciudad es requerida");
        valid = false;
      }      

      // Pricipal Actividad
      if (Validator.isEmpty(form.principalActividad)) {
        setErrorPrincipalActividad("La principal actividad de la empresa es requerida");
        valid = false;
      }      

      // Fecha de inicio de operaciones
      if (Validator.isEmpty(form.empresaFechaComienzoOperaciones)) {
        setErrorEmpresaFechaComienzoOperaciones("La fecha de inicio de operación de la empresa es requerida");
        valid = false;
      }             
      
      if (Validator.isEmpty(form.empresaExporta))
      {
        setErrorEmpresaExporta("Es requerido confirmar si la empresa tiene operaciones de exportación o no");
        valid = false;
      }

      if (!Validator.isEmpty(form.empresaExporta) && form.empresaExporta=="Si" && Validator.isEmpty(form.empresaExportaPaises))
      {
          setErrorEmpresaExportaPaises("Es requerido capturar el nombre de los paises a los que exporta");
          valid = false;
      }

      if (Validator.isEmpty(form.empresaImporta))
      {
        setErrorEmpresaImporta("Es requerido confirmar si la empresa tiene operaciones de importación");
        valid = false;
      }


      if (!Validator.isEmpty(form.empresaImporta) && form.empresaImporta=="Si" && Validator.isEmpty(form.empresaImportaPaises))
      {
          setErrorEmpresaImportaPaises("Es requerido capturar el nombre de los paises de los que importa");
          valid = false;
      }

      if (Validator.isEmpty(form.empresaVentas)) {
        setErrorVentas("El rango de ventas de la empresa del año anterior es requerido");
        valid = false;
      }

      if (Validator.isEmpty(form.empresaCrecimiento)) {
        setErrorCrecimiento("El crecimiento de la empresa es requerido");
        valid = false;
      }

      if (Validator.isEmpty(form.empresaEmpleados)) {
        setErrorEmpleados("El número de empleados en la empresa es requerido");
        valid = false;
      }

      if (Validator.isEmpty(form.empresaInclusiva)) {
        setErrorInclusion("Es requerida su selección respecto a si su empresa es inclusiva con personas con discapacidad");
        valid = false;
      }

      if (Validator.isEmpty(form.empresaSistemaPleneacion)) {
        setErrorPlaneacion("Es requerida su selección respecto a si su empresa cuenta con sistemas de planeación");
        valid = false;
      }

      if (Validator.isEmpty(form.empresaProcesosGestion)) {
        setErrorGestion("Es requerida su selección respecto a si su empresa cuenta con procesos de gestión");
        valid = false;
      }

      if (Validator.isEmpty(form.empresaSustentable)) {
        setErrorSustentable("Es requerida su selección respecto a si las operaciones de su empresa son sustentables");
        valid = false;
      }

      if (Validator.isEmpty(form.empresaTecnologiaPunta))
      {
        setErrorTecnologia("Es requerida su selección respecto a si su empresa cuenta con tecnología de punta");        
        valid = false;
      }

      if (Validator.isEmpty(form.empresaModeloNegocioVanguardia))
      {
        setErrorModeloNegocio("Es requerida su selección respecto a si su empresa cuenta con algún modelo de negocio, calidad, diseño, vanguardia o tecnología");   
        valid = false;
      }
                                 
      if (Validator.isEmpty(form.empresaAsociaciones))
      {
        setErrorEmpresaAsociaciones("Es requerido en que asociaciones o camaras su empresa participa");   
        valid = false;
      }

      if (Validator.isEmpty(form.empresaCertificaciones))
      {
        setErrorCertificaciones("Es requerida su selección respecto a si su empresa cuenta con distintivos o certificaciones");   
        valid = false;
      }

      if (Validator.isEmpty(form.empresaPremioReconocimiento))
      {
        setErrorReconocimiento("Es requerida su selección respecto a si su empresa ha recibido algún reconocimiento o premio");   
        valid = false;
      }

      if (Validator.isEmpty(form.empresaAfiliacionCimejal))
      {
        setErrorCimejal("Es requerida su selección respecto a cuantos años su empresa ha estado afiliada al Cimejal");   
        valid = false;
      }



    if (valid) {
      /*
      const newAttendee = {
        nombres: state.form.nombres,
        apellidos: state.form.apellidos,
        email: state.form.email,

        ciudad: state.form.ciudad,
        pais: state.form.pais,
        estado: state.form.estado,
        codigoPostal: state.form.codigoPostal,

        nombreEmpresa: state.form.nombreEmpresa,
        giroOtros: state.form.giroOtros,

        categoria: _categoria,
        tipoRegistro: state.form.tipoRegistro,
        notas: "EXPRESS",

        locale: language
    }
    */

    registerAttendeeNew(form)
        .then(res => history.push("/landing?&id=" + res.data))
        .catch(error => {
            materialize.toast({ html: locale.button_submission_error })
        });
    }
  };

  return (
    <div className="col s12 m12 l7 container contentaligned">
      <div className="col s12 m12 l12" style={{ paddingLeft: "11.250px" }}>
        <h4>
          <b>{locale.dashboard_comprador_label_personalinformation}</b>
        </h4>
      </div>
      <div className="input-field col s12 m6 l6">
        <input
          autoFocus
          onChange={update}
          value={form.nombres}
          error={errorNombre}
          id="nombres"
          type="text"
           className={classnames("font-face-msb", {
            invalid: errorNombre
          })}
        />
        <label htmlFor="nombres">{locale.dashboard_comprador_label_name}</label>
        <span className="red-text">{errorNombre}</span>
      </div>
      <div className="input-field col s12 m6 l6">
        <input
          onChange={update}
          value={form.apellidos}
          error={errorApellido}
          id="apellidos"
          type="text"
           className={classnames("font-face-msb", {
            invalid: errorApellido
          })}
        />
        <label htmlFor="apellidos">{locale.dashboard_comprador_label_lastname}</label>
        <span className="red-text">{errorApellido}</span>
      </div>
      <div className="input-field col s12 m12 l12">
        <select value={form.sexo} onChange={update} id="sexo">
          <option value="" disabled selected>
            {locale.dashboard_comprador_label_sex}
          </option>
          <option value="F">
            {locale.dashboard_comprador_label_female}
          </option>
          <option value="M">
            {locale.dashboard_comprador_label_male}
          </option>
        </select>
        <label>{locale.dashboard_comprador_label_sex}</label>
        <span className="red-text">{errorSexo}</span>
      </div>      
      <div className="input-field col s12 m6 l6">
        <input
          onChange={update}
          value={form.nacimiento}
          error={errorNacimiento}
          id="nacimiento"
          type="text"
           className={classnames("font-face-msb", {
            invalid: errorNacimiento
          })}
          maxLength="2"
          onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
        />
        <label htmlFor="nacimiento">{locale.dashboard_comprador_label_yearofbirth}</label>
        <span className="red-text">{errorNacimiento}</span>
      </div>      
      <div className="input-field col s12 m6 l6">
        <input
          onChange={update}
          value={form.telefono}
          error={errorTelefono}
          id="telefono"
          type="text"
           className={classnames("font-face-msb", {
            invalid: errorTelefono
          })}
          maxLength="10"
          onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
        />
        <label htmlFor="telefono">{locale.dashboard_comprador_label_phone}</label>
        <span className="red-text">{errorTelefono}</span>
      </div>
      <div className="input-field col s12 m6 l6">
                <input
                    onChange={update}
                    value={form.carrera}
                    error={errorCarrera}
                    id="carrera"
                    type="text"
                     className={classnames("font-face-msb", {
                        invalid: errorCarrera
                    })}
                />
                <label htmlFor="carrera">{locale.auth_visitante_label_career}</label>
                <span className="red-text">{errorCarrera}</span>
      </div>
      <div className="input-field col s12 m6 l6">
        <input
          onChange={update}
          value={form.cargo}
          error={errorCargo}
          id="cargo"
          type="text"
           className={classnames("font-face-msb", {
            invalid: errorCargo
          })}
        />
        <label htmlFor="cargo">{locale.dashboard_comprador_label_companyposition}</label>
        <span className="red-text">{errorCargo}</span>
      </div>
      <div className="input-field col s12 m6 l6">
        <input
          onChange={update}
          value={form.email}
          error={errorEmail}
          id="email"
          type="email"
           className={classnames("font-face-msb", {
            invalid: errorEmail
          })}
        />
        <label htmlFor="email">{locale.dashboard_comprador_label_mail}</label>
        <span className="red-text">{errorEmail}</span>
      </div>      
      <div className="input-field col s12 m6 l6">
        <input
          onChange={update}
          value={form.wwwEmpresa}
          error={errorwwwEmpresa}
          id="wwwEmpresa"
          type="text"
           className={classnames("font-face-msb", {
            invalid: errorwwwEmpresa
          })}
        />
        <label htmlFor="wwwEmpresa">{locale.dashboard_comprador_label_companywebpage}</label>
        <span className="red-text">{errorwwwEmpresa}</span>
      </div>
      <div className="col s12" style={{ paddingLeft: "11.250px" }}>
        <h4>
          <b>{locale.dashboard_comprador_label_companyinformation}</b>
        </h4>
      </div>
      <div className="input-field col s12 m6 l6">
        <input
          onChange={update}
          value={form.rfc}
          error={errorRFC}
          id="rfc"
          type="text"
           className={classnames("font-face-msb", {
            invalid: errorRFC
          })}
        />
        <label htmlFor="rfc">{locale.dashboard_comprador_label_companyRFC}</label>
        <span className="red-text">{errorRFC}</span>
      </div> 
      <div className="input-field col s12 m6 l6">
        <input
          onChange={update}
          value={form.razonSocial}
          error={errorRazonSocial}
          id="razonSocial"
          type="text"
           className={classnames("font-face-msb", {
            invalid: errorRazonSocial
          })}
        />
        <label htmlFor="razonSocial">{locale.dashboard_comprador_label_businessname}</label>
        <span className="red-text">{errorRazonSocial}</span>
      </div>          
      <div className="input-field col s12">
        <input
          onChange={update}
          value={form.nombreEmpresa}
          error={errorNombreEmpresa}
          id="nombreEmpresa"
          type="text"
           className={classnames("font-face-msb", {
            invalid: errorNombreEmpresa
          })}
        />
        <label htmlFor="nombreEmpresa">{locale.dashboard_comprador_label_nameofcompany}</label>
        <span className="red-text">{errorNombreEmpresa}</span>
      </div>

      <div className="input-field col s12 m6 l6">
        <input
          onChange={update}
          value={form.domicilio}
          error={errorDomicilio}
          id="domicilio"
          type="text"
           className={classnames("font-face-msb", {
            invalid: errorDomicilio
          })}
        />
        <label htmlFor="domicilio">{locale.dashboard_comprador_label_Street}</label>
        <span className="red-text">{errorDomicilio}</span>
      </div>

      <div className="input-field col s12 m6 l6">
        <input
          onChange={update}
          value={form.codigoPostal}
          error={errorCodigoPostal}
          id="codigoPostal"
          type="text"
           className={classnames("font-face-msb", {
            invalid: errorCodigoPostal
          })}
          maxLength="10"
          onKeyPress={(event) => { if ((form.pais === "MX" || form.pais === "US") && !/[0-9]/.test(event.key)) { event.preventDefault(); } }}      
        />
        <label htmlFor="codigoPostal">{locale.dashboard_comprador_label_zipcode}</label>
        <span className="red-text">{errorCodigoPostal}</span>
      </div>


      <div className="input-field col s12 m6 l6">
        <select value={form.estado} onChange={update} id="estado">
          <option value="" >
            {locale.dashboard_comprador_label_state_select}
          </option>
          {estados}
        </select>
        <label htmlFor="estado">{locale.dashboard_comprador_label_state}</label>
        <span className="red-text">{errorEstado}</span>
      </div>

      <div className="input-field col s12 m6 l6">
            <select value={form.ciudad} onChange={update} id="ciudad">
              <option value="" >
                {locale.dashboard_comprador_label_city_select}
              </option>
              {ciudades}
            </select>
            <label htmlFor="ciudad">{locale.dashboard_comprador_label_city}</label>
            <span className="red-text">{errorCiudad}</span>
          </div>



      <div className="input-field col s12 m6 l6">
        <input
          onChange={update}
          value={form.principalActividad}
          error={errorPrincipalActividad}
          id="principalActividad"
          type="text"
           className={classnames("font-face-msb", {
            invalid: errorPrincipalActividad
          })}
        />
        <label htmlFor="principalActividad">{locale.dashboard_comprador_label_business}</label>
        <span className="red-text">{errorPrincipalActividad}</span>
      </div>

      
      <div className="input-field col s12 m6 l6">
      
        <input
          onChange={update}
          class="datepicker"
          value={form.empresaFechaComienzoOperaciones}
          error={errorEmpresaFechaComienzoOperaciones}
          id="empresaFechaComienzoOperaciones"
          type="text"
        />
        <label htmlFor="empresaFechaComienzoOperaciones">{locale.dashboard_comprador_label_company_start_operation_date}</label>
        <span className="red-text">{errorEmpresaFechaComienzoOperaciones}</span>

          {/*
                    <DatePicker
                label="Fecha"
                value={form.empresaFechaComienzoOperaciones}
                id="empresaFechaComienzoOperaciones"
                onChange={(newDate) => {
                    updateByKey({
                        target: {
                            id: "empresaFechaComienzoOperaciones",
                            value: newDate
                        }
                    })
                }} />
                        */}
      </div>

        
      <div className="input-field col s12">{locale.dashboard_comprador_label_company_export}</div>
      <div className="col s12">
      <p>
      <label>
        <input name="groupoExporta" type="radio"  id="exportaSi" onChange={update}/>
        <span>Si</span>
      </label>
    </p>
    <p>
      <label>
      <input name="groupoExporta" type="radio"  id="exportaNo" onChange={update}/>
        <span>No</span>
      </label>
    </p> 
    <span className="red-text">{errorEmpresaExporta}</span>
      </div>

      <div className="input-field col s12">
        <input
          onChange={update}
          value={form.empresaExportaPaises}
          error={errorEmpresaExportaPaises}
          id="empresaExportaPaises"
          type="text"
           className={classnames("font-face-msb", {
            invalid: errorEmpresaExportaPaises
          })}
        />
        <label htmlFor="empresaExportaPaises"> A que país?</label>
        <span className="red-text">{errorEmpresaExportaPaises}</span>
      </div>


      <div className="input-field col s12">{locale.dashboard_comprador_label_company_import}</div>
      <div className="col s12">
      <p>
      <label>
        <input name="groupoImporta" type="radio"  id="importaSi" onChange={update}/>
        <span>Si</span>
      </label>
    </p>
    <p>
      <label>
      <input name="groupoImporta" type="radio"  id="importaNo" onChange={update}/>
        <span>No</span>
      </label>
    </p> 
    <span className="red-text">{errorEmpresaImporta}</span>
      </div>

      <div className="input-field col s12">
        <input
          onChange={update}
          value={form.empresaImportaPaises}
          error={errorEmpresaImportaPaises}
          id="empresaImportaPaises"
          type="text"
           className={classnames("font-face-msb", {
            invalid: errorEmpresaImportaPaises
          })}
        />
        <label htmlFor="empresaImportaPaises"> A que país?</label>
        <span className="red-text">{errorEmpresaImportaPaises}</span>
      </div>


      <div className="input-field col s12">Rango de ventas netas del año anterior</div>
      <div className="col s12">
      <p>
      <label>
        <input name="groupVentas" type="radio" id="ventas1" onChange={update} />
        <span> 0 a 12,000,000</span>
      </label>
    </p>
    <p>
      <label>
        <input name="groupVentas" type="radio" id="ventas2" onChange={update}  />
        <span>12,001,000 a 30,00,000</span>
      </label>
    </p>
    <p>
      <label>
        <input name="groupVentas" type="radio" id="ventas3" onChange={update}/>
        <span>30,001,000 a más</span>
      </label>
    </p>
    <span className="red-text">{errorVentas}</span>
      </div>

      <div className="input-field col s12">El crecimiento de su empresa en los ulitmos 5 años, ha sido de: </div>
      <div className="col s12">
      <p>
      <label>
        <input name="groupoCrecimiento" type="radio" id="crecimiento1" onChange={update} />
        <span>0% a 10%</span>
      </label>
    </p>
    <p>
      <label>
        <input name="groupoCrecimiento"  type="radio" id="crecimiento2" onChange={update}  />
        <span>11% a 20%</span>
      </label>
    </p>
    <p>
      <label>
        <input name="groupoCrecimiento"  type="radio" id="crecimiento3" onChange={update}/>
        <span>Más del 20%  </span>
      </label>
    </p>
    <span className="red-text">{errorCrecimiento}</span>
      </div>

      <div className="input-field col s12">Cuántos empleados tiene la empresa? </div>
      <div className="col s12">
      <p>
      <label>
        <input name="groupEmpledados" type="radio"  id="empleados1" onChange={update}/>
        <span>1 a 15 empleados</span>
      </label>
    </p>
    <p>
      <label>
      <input name="groupEmpledados" type="radio"  id="empleados2" onChange={update}/>
        <span>16 a 100 empleados</span>
      </label>
    </p>
    <p>
      <label>
      <input name="groupEmpledados" type="radio"  id="empleados3" onChange={update}/>
        <span>101 a 250 empleados</span>
      </label>
    </p>
    <p>
      <label>
      <input name="groupEmpledados" type="radio"  id="empleados4" onChange={update}/>
        <span>251 empleados en adelante</span>
      </label>
    </p>    
    <span className="red-text">{errorEmpleados}</span>
      </div>

      <div className="input-field col s12">Es una empresa inclusiva de personas con discapacidad?</div>
      <div className="col s12">
      <p>
      <label>
        <input name="groupoInclusion" type="radio" id="inclusionSi" onChange={update}/>
        <span>Si</span>
      </label>
    </p>
    <p>
      <label>
        <input name="groupoInclusion" type="radio" id="inclusionNo" onChange={update}  />
        <span>No</span>
      </label>
    </p>
    <span className="red-text">{errorInclusion}</span> 
      </div>
      <div className="input-field col s12">Cuenta con sistemas de planeación?</div>
      <div className="col s12">
      <p>
      <label>
        <input name="groupoPlaneacion" type="radio"  id="planeacionSi" onChange={update}/>
        <span>Si</span>
      </label>
    </p>
    <p>
      <label>
      <input name="groupoPlaneacion" type="radio"  id="planeacionNo" onChange={update}/>
        <span>No</span>
      </label>
    </p> 
    <span className="red-text">{errorPlaneacion}</span> 
      </div>

      <div className="input-field col s12">Implementación de procesos de gestión?</div>
      <div className="col s12">
      <p>
      <label>
      <input name="groupoGestion" type="radio"  id="gestionSi" onChange={update}/>
        <span>Si</span>
      </label>
    </p>
    <p>
      <label>
      <input name="groupoGestion" type="radio"  id="gestionNo" onChange={update}/>
        <span>No</span>
      </label>
    </p> 
    <span className="red-text">{errorGestion}</span> 
      </div>

      <div className="input-field col s12">Las operaciones de la empresa son sustentables?</div>
      <div className="col s12">
      <p>
      <label>
      <input name="groupoSustentable" type="radio"  id="sustentableSi" onChange={update}/>
        <span>Si</span>
      </label>
    </p>
    <p>
      <label>
      <input name="groupoSustentable" type="radio"  id="sustentableNo" onChange={update}/>
        <span>No</span>
      </label>
    </p> 
    <span className="red-text">{errorSustentable}</span> 
      </div>

      <div className="input-field col s12">Cuenta con tecnología de punta?</div>
      <div className="col s12">
      <p>
      <label>
      <input name="groupoTecnologia" type="radio"  id="tecnologiaSi" onChange={update}/>
        <span>Si</span>
      </label>
    </p>
    <p>
      <label>
      <input name="groupoTecnologia" type="radio"  id="tecnologiaNo" onChange={update}/>
        <span>No</span>
      </label>
    </p> 
    <span className="red-text">{errorTecnologia}</span> 
      </div>

      <div className="input-field col s12">Cuenta con algún modelo de negocio, calidad, diseño, vanguardia o tecnología?</div>
      <div className="col s12">
      <p>
      <label>
      <input name="groupoVanguardia" type="radio"  id="vanguardiaSi" onChange={update}/>
        <span>Si</span>
      </label>
    </p>
    <p>
      <label>
      <input name="groupoVanguardia" type="radio"  id="vanguardiaNo" onChange={update}/>
        <span>No</span>
      </label>
    </p> 
    <span className="red-text">{errorModeloNegocio}</span> 
    </div>
    <div className="input-field col s12">
        <input
          onChange={update}
          value={form.empresaAsociaciones}
          error={errorEmpresaAsociaciones}
          id="empresaAsociaciones"
          type="text"
           className={classnames("font-face-msb", {
            invalid: errorEmpresaAsociaciones
          })}
        />
        <label htmlFor="empresaAsociaciones">En que asociaciones o camaras empresariales participa?</label>
        <span className="red-text">{errorEmpresaAsociaciones}</span>
      </div>

      <div className="input-field col s12">Cuenta con distintivos o certifiaciones?</div>
      <div className="col s12">
      <p>
      <label>
      <input name="groupoCertificacion" type="radio"  id="certificacionSi" onChange={update}/>
        <span>Si</span>
      </label>
    </p>
    <p>
      <label>
      <input name="groupoCertification" type="radio"  id="certificacionNo" onChange={update}/>
        <span>No</span>
      </label>
    </p> 
    <span className="red-text">{errorCertificaciones}</span> 
      </div>

      <div className="input-field col s12">La empresa a recibido algún reconocimiento o premio?</div>
      <div className="col s12">
      <p>
      <label>
      <input name="groupoReconocimiento" type="radio"  id="reconocimientoSi" onChange={update}/>
        <span>Si</span>
      </label>
    </p>
    <p>
      <label>
      <input name="groupoReconocimiento" type="radio"  id="reconocimientoNo" onChange={update}/>
        <span>No</span>
      </label>
    </p> 
    <span className="red-text">{errorReconocimiento}</span> 
      </div>

      <div className="input-field col s12">Desde que fecha eres afiiado en Cimejal? </div>
      <div className="col s12">
      <p>
      <label>
      <input name="groupoAfiliado" type="radio"  id="afiliado1" onChange={update}/>
        <span>0 a 5 años </span>
      </label>
    </p>
    <p>
      <label>
      <input name="groupoAfiliado" type="radio"  id="afiliado2" onChange={update}/>
        <span>6 a 10 años </span>
      </label>
    </p> 
    <p>
      <label>
      <input name="groupoAfiliado" type="radio"  id="afiliado3" onChange={update}/>
        <span>11 a 15 años </span>
      </label>
    </p>  
    <p>
      <label>
      <input name="groupoAfiliado" type="radio"  id="afiliado4" onChange={update}/>
        <span>16 a 20 años </span>
      </label>
    </p> 
    <p>
      <label>
      <input name="groupoAfiliado" type="radio"  id="afiliado5" onChange={update}/>
        <span>21 a 25 años</span>
      </label>
    </p>                
    <span className="red-text">{errorCimejal}</span>     
      </div>     
      <div className="col s12 m12 l12">
      <button
            style={{
              width: "200px",
              borderRadius: "3px",
              letterSpacing: "1.5px",
              marginTop: "1rem"
            }}
            className="btn-large hoverable buttonBackgroundColor font-face-msb"
            onClick={submitForm}
          >
            {locale.button_wizard_finish}
          </button>   
          </div>
    </div>
  );
};


export default (withRouter(CimejalForm));
