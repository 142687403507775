import React, { useEffect, useState } from "react";
import { Link, withRouter, useHistory } from "react-router-dom";
import classnames from "classnames";
import Validator from "validator";
import { Stats } from "./Stats";
import { getCountries, getStates, getCities } from "../../actions/countriesActions";
import materialize from "materialize-css";
import { getZipCode } from "../../actions/zipcodeActions";
import Localization from "../localization/localization"
//import { DatePicker } from "react-materialize";
import moment from"moment";
import { registerAttendeeNew } from "../../actions/attendeeActions";



export const PostulaForm = (props) => {

  const [form, setform] = useState({});

  const [estados, setEstados] = useState("");
  const [ciudades, setCiudades] = useState("");  
  const [state, setState] = useState({
    form: {
        nombres: "",
        apellidos: "",
        sexo: "",
        telefono: "",
        cargo: "",
        email: "",
        nombreEmpresa: "",

        postuladoNombres: "",
        postuladoApellidos: "",
        postuladoSexo: "",
        postuladoTelefono: "",
        postuladoCargo: "",
        postuladoEmail: "",
        postuladoNombreEmpresa: "",
        postuladoMotivo: "",

        categoria: "POSTULACION",
        locale: "es",
    }
});

  const [errorNombre, setErrorNombre] = useState('');
  const [errorApellido, setErrorApellido] = useState('');
  const [errorTelefono, setErrorTelefono] = useState('');
  const [errorSexo, setErrorSexo] = useState('');
  const [errorCargo, setErrorCargo] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorNombreEmpresa, setErrorNombreEmpresa] = useState('');


  const [errorPostuladoNombre, setErrorPostuladoNombre] = useState('');
  const [errorPostuladoApellido, setErrorPostuladoApellido] = useState('');
  const [errorPostuladoTelefono, setErrorPostuladoTelefono] = useState('');
  const [errorPostuladoSexo, setErrorPostuladoSexo] = useState('');
  const [errorPostuladoCargo, setErrorPostuladoCargo] = useState('');
  const [errorPostuladoEmail, setErrorPostuladoEmail] = useState('');
  const [errorPostuladoNombreEmpresa, setErrorPostuladoNombreEmpresa] = useState('');
  const [errorPostuladoMotivo, setErrorPostuladoMotivo] = useState('');


  const locale = Localization.getLocalizationInstance("MX");
  const history = useHistory();


  const update = (e) => {

    let key  = e.target.id;
    let value = e.target.value;    
    const { form } = state;

    form[key] = value;
    setState({
        ...state,
        form,
    });
  };

  

  const submitForm = () => {

    const { form } = state;
    let valid = true;

    // Errors clean up

    setErrorNombre("");
    setErrorApellido("");
    setErrorTelefono("");
    setErrorSexo("");
    setErrorCargo("");
    setErrorEmail("");
    setErrorNombreEmpresa("");
  
  
    setErrorPostuladoNombre("");
    setErrorPostuladoApellido("");
    setErrorPostuladoTelefono("");
    setErrorPostuladoSexo("");
    setErrorPostuladoCargo("");
    setErrorPostuladoEmail("");
    setErrorPostuladoNombreEmpresa("");
    setErrorPostuladoMotivo("");


      // Nombres
      if (Validator.isEmpty(form.nombres)) {
        setErrorNombre("Nombre es requerido");
        valid = false;
      }

      // Apellidos
      if (Validator.isEmpty(form.apellidos)) {
        setErrorApellido("Apellido es requerido");
        valid = false;
      }    

      // Género
      if (Validator.isEmpty(form.sexo)) {
        setErrorSexo("Género es requerido");
        valid = false;
      }    

      // Telefono 
      if (!Validator.isEmpty(form.telefono)) {
        if (Validator.isInt(form.telefono)) {
          if (!Validator.isLength(form.telefono, { min: 10, max: 10 })) {
            setErrorTelefono("Numero de teléfono Invalido, numero esperado a 10 digitos");
            valid = false;
          }
        }
        else {
          setErrorTelefono("Numero de teléfono Invalido, numero esperado a 10 digitos");
          valid = false;
        }
      }
      else {
        setErrorTelefono("Numero de teléfono es requerido");
        valid = false;
      }

      // Cargo
      if (Validator.isEmpty(form.cargo)) {
        setErrorCargo("Su posición o cargo en la empresa en requerida");
        valid = false;
      }

      // Email checks
      if (Validator.isEmpty(form.email)) {
        setErrorEmail("Email es requerido");
        valid = false;
      } else if (!Validator.isEmail(form.email)) {
        setErrorEmail("Email es invalido");
        valid = false;
      }

      // Nombre Empresa
      if (Validator.isEmpty(form.nombreEmpresa)) {
        setErrorNombreEmpresa("El nombre comercial de la empresa es requerido");
        valid = false;
      }


      /// Postulado
      // Nombres 
      if (Validator.isEmpty(form.postuladoNombres)) {
        setErrorPostuladoNombre("Nombre del postulado es requerido");
        valid = false;
      }

      // Apellidos
      if (Validator.isEmpty(form.postuladoApellidos)) {
        setErrorPostuladoApellido("Apellido del postulado es requerido");
        valid = false;
      }    

      // Género
      if (Validator.isEmpty(form.postuladoSexo)) {
        setErrorPostuladoSexo("Género del postulado es requerido");
        valid = false;
      }    

      // Telefono 
      if (!Validator.isEmpty(form.postuladoTelefono)) {
        if (Validator.isInt(form.postuladoTelefono)) {
          if (!Validator.isLength(form.postuladoTelefono, { min: 10, max: 10 })) {
            setErrorPostuladoTelefono("Numero de teléfono del postulado inválido, numero esperado a 10 digitos");
            valid = false;
          }
        }
        else {
          setErrorPostuladoTelefono("Numero de teléfono del postulado inválido, numero esperado a 10 digitos");
          valid = false;
        }
      }
      else {
        setErrorPostuladoTelefono("Numero de teléfono del postulado es requerido");
        valid = false;
      }

      // Cargo
      if (Validator.isEmpty(form.postuladoCargo)) {
        setErrorPostuladoCargo("Posición o cargo del postulado en la empresa en requerida");
        valid = false;
      }

      // Email checks
      if (Validator.isEmpty(form.postuladoEmail)) {
        setErrorPostuladoEmail("Email del postulado es requerido");
        valid = false;
      } else if (!Validator.isEmail(form.postuladoEmail)) {
        setErrorPostuladoEmail("Email del postulado es inválido");
        valid = false;
      }

      // Nombre Empresa
      if (Validator.isEmpty(form.postuladoNombreEmpresa)) {
        setErrorPostuladoNombreEmpresa("El nombre comercial de la empresa del postulado es requerido");
        valid = false;
      }

      // Notas
      if (Validator.isEmpty(form.postuladoMotivo)) {
        setErrorPostuladoMotivo("El motivo por el cual esta postulando a esta empresa o persona es requerido");
        valid = false;
      }





    if (valid) {
    registerAttendeeNew(form)
        .then(res => history.push("/landing?&id=" + res.data))
        .catch(error => {
            materialize.toast({ html: locale.button_submission_error })
        });
    }
  };

  return (
    <div className="col s12 m12 l7 container contentaligned">
      <div className="col s12 m12 l12" style={{ paddingLeft: "11.250px" }}>
        <h4>
          <b>Datos Generales de quién postula</b>
        </h4>
      </div>
      <div className="input-field col s12 m6 l6">
        <input
          autoFocus
          onChange={update}
          value={form.nombres}
          error={errorNombre}
          id="nombres"
          type="text"
           className={classnames("font-face-msb", {
            invalid: errorNombre
          })}
        />
        <label htmlFor="nombres">{locale.dashboard_comprador_label_name}</label>
        <span className="red-text">{errorNombre}</span>
      </div>
      <div className="input-field col s12 m6 l6">
        <input
          onChange={update}
          value={form.apellidos}
          error={errorApellido}
          id="apellidos"
          type="text"
           className={classnames("font-face-msb", {
            invalid: errorApellido
          })}
        />
        <label htmlFor="apellidos">{locale.dashboard_comprador_label_lastname}</label>
        <span className="red-text">{errorApellido}</span>
      </div>
      <div className="input-field col s12 m6 l6">
        <select value={form.sexo} onChange={update} id="sexo">
          <option value="" disabled selected>
            {locale.dashboard_comprador_label_sex}
          </option>
          <option value="F">
            {locale.dashboard_comprador_label_female}
          </option>
          <option value="M">
            {locale.dashboard_comprador_label_male}
          </option>
        </select>
        <label>{locale.dashboard_comprador_label_sex}</label>
        <span className="red-text">{errorSexo}</span>
      </div>      

      
      <div className="input-field col s12 m6 l6">
        <input
          onChange={update}
          value={form.cargo}
          error={errorCargo}
          id="cargo"
          type="text"
           className={classnames("font-face-msb", {
            invalid: errorCargo
          })}
        />
        <label htmlFor="cargo">*Puesto</label>
        <span className="red-text">{errorCargo}</span>
      </div>


      <div className="input-field col s12 m6 l6">
        <input
          onChange={update}
          value={form.telefono}
          error={errorTelefono}
          id="telefono"
          type="text"
           className={classnames("font-face-msb", {
            invalid: errorTelefono
          })}
          maxLength="10"
          onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
        />
        <label htmlFor="telefono">{locale.dashboard_comprador_label_phone}</label>
        <span className="red-text">{errorTelefono}</span>
      </div>



      <div className="input-field col s12 m6 l6">
        <input
          onChange={update}
          value={form.email}
          error={errorEmail}
          id="email"
          type="email"
           className={classnames("font-face-msb", {
            invalid: errorEmail
          })}
        />
        <label htmlFor="email">{locale.dashboard_comprador_label_mail}</label>
        <span className="red-text">{errorEmail}</span>
      </div>      
      
      <div className="input-field col s12">
        <input
          onChange={update}
          value={form.nombreEmpresa}
          error={errorNombreEmpresa}
          id="nombreEmpresa"
          type="text"
           className={classnames("font-face-msb", {
            invalid: errorNombreEmpresa
          })}
        />
        <label htmlFor="nombreEmpresa">*Nombre comercial de la empresa</label>
        <span className="red-text">{errorNombreEmpresa}</span>
      </div>

      <div className="col s12" style={{ paddingLeft: "11.250px" }}>
        <h4>
          <b>Datos Generales del postulado</b>
        </h4>
      </div>
      <div className="input-field col s12 m6 l6">
        <input
          onChange={update}
          value={form.postuladoNombres}
          error={errorPostuladoNombre}
          id="postuladoNombres"
          type="text"
           className={classnames("font-face-msb", {
            invalid: errorPostuladoNombre
          })}
        />
        <label htmlFor="postuladoNombres">{locale.dashboard_comprador_label_name}</label>
        <span className="red-text">{errorPostuladoNombre}</span>
      </div>
      <div className="input-field col s12 m6 l6">
        <input
          onChange={update}
          value={form.postuladoApellidos}
          error={errorPostuladoApellido}
          id="postuladoApellidos"
          type="text"
           className={classnames("font-face-msb", {
            invalid: errorPostuladoApellido
          })}
        />
        <label htmlFor="postuladoApellidos">{locale.dashboard_comprador_label_lastname}</label>
        <span className="red-text">{errorPostuladoApellido}</span>
      </div>
      <div className="input-field col s12 m6 l6">
        <select value={form.postuladoSexo} onChange={update} id="postuladoSexo">
          <option value="" disabled selected>
            {locale.dashboard_comprador_label_sex}
          </option>
          <option value="F">
            {locale.dashboard_comprador_label_female}
          </option>
          <option value="M">
            {locale.dashboard_comprador_label_male}
          </option>
        </select>
        <label>{locale.dashboard_comprador_label_sex}</label>
        <span className="red-text">{errorPostuladoSexo}</span>
      </div>    

      <div className="input-field col s12 m6 l6">
        <input
          onChange={update}
          value={form.postuladoCargo}
          error={errorPostuladoCargo}
          id="postuladoCargo"
          type="text"
           className={classnames("font-face-msb", {
            invalid: errorPostuladoCargo
          })}
        />
        <label htmlFor="postuladoCargo">*Puesto</label>
        <span className="red-text">{errorPostuladoCargo}</span>
      </div>  

      <div className="input-field col s12 m6 l6">
        <input
          onChange={update}
          value={form.postuladoTelefono}
          error={errorPostuladoTelefono}
          id="postuladoTelefono"
          type="text"
           className={classnames("font-face-msb", {
            invalid: errorPostuladoTelefono
          })}
          maxLength="10"
          onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
        />
        <label htmlFor="postuladoTelefono">{locale.dashboard_comprador_label_phone}</label>
        <span className="red-text">{errorPostuladoTelefono}</span>
      </div>




      <div className="input-field col s12 m6 l6">
        <input
          onChange={update}
          value={form.postuladoEmail}
          error={errorPostuladoEmail}
          id="postuladoEmail"
          type="email"
           className={classnames("font-face-msb", {
            invalid: errorPostuladoEmail
          })}
        />
        <label htmlFor="postuladoEmail">{locale.dashboard_comprador_label_mail}</label>
        <span className="red-text">{errorPostuladoEmail}</span>
      </div>      
      
      <div className="input-field col s12">
        <input
          onChange={update}
          value={form.postuladoNombreEmpresa}
          error={errorPostuladoNombreEmpresa}
          id="postuladoNombreEmpresa"
          type="text"
           className={classnames("font-face-msb", {
            invalid: errorPostuladoNombreEmpresa
          })}
        />
        <label htmlFor="postuladoNombreEmpresa">Nombre Comercial de la Empresa ó marca:</label>
        <span className="red-text">{errorNombreEmpresa}</span>
      </div>
      

      <div className="input-field col s12">
        <input
          onChange={update}
          value={form.postuladoMotivo}
          error={errorPostuladoMotivo}
          id="postuladoMotivo"
          type="text"
           className={classnames("font-face-msb", {
            invalid: errorPostuladoMotivo
          })}
        />
        <label htmlFor="postuladoMotivo">Motivo por la cual esta postulando a la persona y empresa</label>
        <span className="red-text">{errorPostuladoMotivo}</span>
      </div>
      




      <div className="col s12 m12 l12">
      <button
            style={{
              width: "200px",
              borderRadius: "3px",
              letterSpacing: "1.5px",
              marginTop: "1rem"
            }}
            className="btn-large hoverable buttonBackgroundColor font-face-msb"
            onClick={submitForm}
          >
            {locale.button_wizard_finish}
          </button>   
        </div>
    </div>
  );
};


export default (withRouter(PostulaForm));
