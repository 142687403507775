import React, { useState } from "react";
import { Link, withRouter, useHistory } from "react-router-dom";
import { registerAttendeeNew } from "../../actions/attendeeActions";
import materialize from "materialize-css"
import Localization from "../localization/localization"
import StepWizard from "react-step-wizard";
import { CompanyInformation } from "./CompanyInformation"
import { CompanyInformationExtended } from "./CompanyInformationExtended";
import { CompanyInformationExtended2 } from "./CompanyInformationExtended2";
import { CompanyInformationExtended3 } from "./CompanyInformationExtended3";
//import TestHooks from "./TestHooks"

import {TestHooks} from "./TestHooks"

import { Email } from "./Email"
import { GeneralInformation } from "./GeneralInformation";


const Cimejal = () => {
    const [state, updateState] = useState({
        form: {
            nombres: "",
            apellidos: "",
            sexo: "",
            nacimiento: "",
            telefono: "",
            carrera: "",
            cargo: "",
            email: "",
            wwwEmpresa: "",

            rfc:"",
            razonSocial: "",
            nombreEmpresa: "",
            domicilio: "",
            codigoPostal: "",
            pais: "MX",
            estado: "",
            ciudad: "",
            principalActividad: "",

            companyStartOperationsDate:"",
            companyExport: "",
            companyImport: "",

            companyExportCountries:"",
            compnayImportCountries:"",
            companySalesRange: "",
            companyGrowth:"",
            companyEmployees:"",
            companyInclusive: "",
            companyERP: "",
            companyProcessManagement:"",
            companySustainableOperations: "",
            companyTech: "",
            companyBusinessModel:"",
            compnanyAssociations: "",
            companyCertified: "",
            companyPrize: "",
            compayCimejalAfiliation: "",

            categoria: "AUTPPOSTULACION",
            locale: "es",
        }
    });

    const [disableSubmit, setdisableSubmit] = useState(false);

    const locale = Localization.getLocalizationInstance("MX");
    const history = useHistory();

    const updateForm = (key, value) => {
        const { form } = state;

        form[key] = value;
        updateState({
            ...state,
            form,
        });

        if (key === "pais") {
            form["ciudad"] = "";
            updateState({
                ...state,
                form,
            });
        }
    };

    const submitForm = () => {

        //e.preventDefault();
        setdisableSubmit(true);
        let language = localStorage.getItem("exmi_language");
        if (language == null)
            language = "es";

        let _categoria = getCategoria(state.form.giroOtros)

        const newAttendee = {
            nombres: state.form.nombres,
            apellidos: state.form.apellidos,
            email: state.form.email,

            ciudad: state.form.ciudad,
            pais: state.form.pais,
            estado: state.form.estado,
            codigoPostal: state.form.codigoPostal,

            nombreEmpresa: state.form.nombreEmpresa,
            giroOtros: state.form.giroOtros,

            categoria: _categoria,
            tipoRegistro: state.form.tipoRegistro,
            notas: "EXPRESS",

            locale: language
        }

        registerAttendeeNew(newAttendee)
            .then(res => history.push("/landing?&id=" + res.data))
            .catch(error => {
                setdisableSubmit(false);
                materialize.toast({ html: locale.button_submission_error })
            });
    }


    const getCategoria = (giroEmpresa) => {

        giroEmpresa = giroEmpresa.trim()

        if (giroEmpresa !== '') {
            return 'COMPRADOR'
        }

        return "VISITANTE"
    }

    // Do something on step change
    const onStepChange = (stats) => {
        window.scroll(0, 0);
        materialize.AutoInit();
        materialize.updateTextFields();
    };

    const setInstance = SW => updateState({
        ...state,
        SW,
    });


    return (
        <div className="col s12 m12 l7 container contentaligned">

                <GeneralInformation update={updateForm} locale={locale} form={state.form}/>
                <CompanyInformation update={updateForm} locale={locale} form={state.form} />
                <CompanyInformationExtended update={updateForm} locale={locale} form={state.form} />
                <CompanyInformationExtended2 update={updateForm} locale={locale} form={state.form} />
                <CompanyInformationExtended3 update={updateForm} locale={locale} form={state.form} />


        </div>
    );
};


export default (withRouter(Cimejal));
