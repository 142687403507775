import React, { useEffect, useState } from "react";
import classnames from "classnames";
import Validator from "validator";
import { Stats } from "./Stats";
import { getCountries, getStates, getCities } from "../../actions/countriesActions";
import materialize from "materialize-css";
import { getZipCode } from "../../actions/zipcodeActions";


export const CompanyInformationExtended = (props) => {

  
  const [errorNombreEmpresa, setErrorNombreEmpresa] = useState('')
  const [errorRFC, setErrorRFC] = useState('');
  const [errorPrincipalActividad, setErrorPrincipalActividad] = useState('');
  const [errorCompanyStartOperationsDate, setCompanyStartOperationsDate] = useState('');
  const [errorCompanyExportCountries, setCompanyErrorExportCountries] = useState('');
  const [errorCompanyImportCountries, setCompanyErrorImportCountries] = useState('');
  const [errorCargo, setErrorCargo] = useState('');
  const [errorCodigoPostalEmpresa, setErrorCodigoPostalEmpresa] = useState('');
  const [errorTelefono, setErrorTelefono] = useState('');
  const [errorPais, setErrorPais] = useState('');
  const [errorEstado, setErrorEstado] = useState('');
  const [errorCiudadEmpresa, setErrorCiudadEmpresa] = useState('');

  const errorRazonSocial = '';
  //const errorColonia = '';
  const errorDomicilio = '';
  //const errorDomicilioNumero = '';
  const errorwwwEmpresa = '';
  const errorRedesSociales = '';




  const update = (e) => {

    props.update(e.target.id, e.target.value);
  };


  const validate = async () => {
    let valid = true;
    let language = localStorage.getItem("exmi_language");


    if (valid)
      props.nextStep();
  };

  return (
    <div>
      <div className="input-field col s12">Rango de ventas netas del año anterior</div>
      <div className="col s12">
      <p>
      <label>
        <input name="group1" type="radio" onChange={update} checked/>
        <span> 0 a 12,000,000</span>
      </label>
    </p>
    <p>
      <label>
        <input name="group1" type="radio" onChange={update}  />
        <span>12,001,000 a 30,00,000</span>
      </label>
    </p>
    <p>
      <label>
        <input name="group1" type="radio" onChange={update}/>
        <span>30,001,000 a más</span>
      </label>
    </p>
      </div>

      <div className="input-field col s12">El crecimiento de su empresa en los ulitmos 5 años, ha sido de: </div>
      <div className="col s12">
      <p>
      <label>
        <input name="group1" type="radio" onChange={update} checked/>
        <span>0% a 10%</span>
      </label>
    </p>
    <p>
      <label>
        <input name="group1" type="radio" onChange={update}  />
        <span>11% a 20%</span>
      </label>
    </p>
    <p>
      <label>
        <input name="group1" type="radio" onChange={update}/>
        <span>Más del 20%  </span>
      </label>
    </p>
      </div>

      <div className="input-field col s12">Cuántos empleados tiene la empresa? </div>
      <div className="col s12">
      <p>
      <label>
        <input name="group1" type="radio" onChange={update} checked/>
        <span>1 a 15 empleados</span>
      </label>
    </p>
    <p>
      <label>
        <input name="group1" type="radio" onChange={update}  />
        <span>16 a 100 empleados</span>
      </label>
    </p>
    <p>
      <label>
        <input name="group1" type="radio" onChange={update}/>
        <span>101 a 250 empleados</span>
      </label>
    </p>
    <p>
      <label>
        <input name="group1" type="radio" onChange={update}/>
        <span>251 empleados en adelante</span>
      </label>
    </p>    
      </div>

      <div className="input-field col s12">Es una empresa inclusiva de personas con discapacidad?</div>
      <div className="col s12">
      <p>
      <label>
        <input name="group1" type="radio" onChange={update} checked/>
        <span>Si</span>
      </label>
    </p>
    <p>
      <label>
        <input name="group1" type="radio" onChange={update}  />
        <span>No</span>
      </label>
    </p> 
      </div>

      {/*<Stats step={4} {...props} nextStep={validate} />*/}
    </div>
  )
};