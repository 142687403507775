import React, { Component } from "react";
import queryString from 'query-string'
import { Link } from "react-router-dom";
import { getAttendeeByAttendeeId } from "../../actions/attendeeActions"
import Localization from "../localization/localization"
import ReactPixel from 'react-facebook-pixel';

class Landing extends Component {

  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      categoria: ""
    };

    this.locale = Localization.getLocalizationInstance(localStorage.getItem("exmi_language"));

    ReactPixel.init('869801026716151', {}, { debug: false, autoConfig: false });
    ReactPixel.pageView();
    //ReactPixel.fbq('track', 'PageView');
  }

  componentDidMount() {
    const params = queryString.parse(this.props.location.search)


    getAttendeeByAttendeeId(params.id)
      .then(res => this.setState({ name: res.data.nombres, email: res.data.email, categoria: res.data.categoria }))
      .catch(err => {
        console.log(err);
        return null;
      });
  }

  render() {
    return (
      <div class="col s12 m12 l8 container">
        <div class="col s12 l10 offset-l1 center contentaligned valign-wrapper">
          <div>
            <h4 class="font-face-mb">
              <p>{this.locale.auth_landingsuccess_label_hello}{this.state.name} {this.locale.auth_landingsuccess_label_message_success}</p>
            </h4>
            <h6>
              <p>{this.locale.auth_landingsuccess_label_message_sent}<span className="font-face-mb">{this.state.email}</span></p>
            </h6>
            {(this.state.categoria === "VISITANTE") ?
                  <>
            <br></br>
            <br></br>
            <Link
                to="/boletos"
                style={{
                  width: "300px",
                  borderRadius: "3px",
                  letterSpacing: "1.5px",
                  marginTop: "1rem"
                }}
                className="btn-large waves-effect waves-light hoverable buttonBackgroundColor"
              >
                Comprar otro boleto?
              </Link>
              </>
              :
              <></>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default Landing;