import React, { useEffect, useState } from "react";
import classnames from "classnames";
import Validator from "validator";
import { Stats } from "./Stats";
import { getCountries, getStates, getCities } from "../../actions/countriesActions";
import materialize from "materialize-css";
import { getZipCode } from "../../actions/zipcodeActions";


export const CompanyInformationExtended2 = (props) => {

  
  const [errorNombreEmpresa, setErrorNombreEmpresa] = useState('')
  const [errorRFC, setErrorRFC] = useState('');
  const [errorPrincipalActividad, setErrorPrincipalActividad] = useState('');
  const [errorCompanyStartOperationsDate, setCompanyStartOperationsDate] = useState('');
  const [errorCompanyExportCountries, setCompanyErrorExportCountries] = useState('');
  const [errorCompanyImportCountries, setCompanyErrorImportCountries] = useState('');
  const [errorCargo, setErrorCargo] = useState('');
  const [errorCodigoPostalEmpresa, setErrorCodigoPostalEmpresa] = useState('');
  const [errorTelefono, setErrorTelefono] = useState('');
  const [errorPais, setErrorPais] = useState('');
  const [errorEstado, setErrorEstado] = useState('');
  const [errorCiudadEmpresa, setErrorCiudadEmpresa] = useState('');

  const errorRazonSocial = '';
  //const errorColonia = '';
  const errorDomicilio = '';
  //const errorDomicilioNumero = '';
  const errorwwwEmpresa = '';
  const errorRedesSociales = '';




  const update = (e) => {

    props.update(e.target.id, e.target.value);
  };


  const validate = async () => {
    let valid = true;
    let language = localStorage.getItem("exmi_language");


    if (valid)
      props.nextStep();
  };

  return (
    <div>
      <div className="input-field col s12">Cuenta con sistemas de planeación?</div>
      <div className="col s12">
      <p>
      <label>
        <input name="group1" type="radio" onChange={update} checked/>
        <span>Si</span>
      </label>
    </p>
    <p>
      <label>
        <input name="group1" type="radio" onChange={update}  />
        <span>No</span>
      </label>
    </p> 
      </div>

      <div className="input-field col s12">Implementación de procesos de gestión?</div>
      <div className="col s12">
      <p>
      <label>
        <input name="group1" type="radio" onChange={update} checked/>
        <span>Si</span>
      </label>
    </p>
    <p>
      <label>
        <input name="group1" type="radio" onChange={update}  />
        <span>No</span>
      </label>
    </p> 
      </div>

      <div className="input-field col s12">Las operaciones de la empresa son sustentables?</div>
      <div className="col s12">
      <p>
      <label>
        <input name="group1" type="radio" onChange={update} checked/>
        <span>Si</span>
      </label>
    </p>
    <p>
      <label>
        <input name="group1" type="radio" onChange={update}  />
        <span>No</span>
      </label>
    </p> 
      </div>

      <div className="input-field col s12">Cuenta con tecnología de punta?</div>
      <div className="col s12">
      <p>
      <label>
        <input name="group1" type="radio" onChange={update} checked/>
        <span>Si</span>
      </label>
    </p>
    <p>
      <label>
        <input name="group1" type="radio" onChange={update}  />
        <span>No</span>
      </label>
    </p> 
      </div>

      <div className="input-field col s12">Cuenta con algún modelo de negocio, calidad, diseño, vanguardia o tecnología?</div>
      <div className="col s12">
      <p>
      <label>
        <input name="group1" type="radio" onChange={update} checked/>
        <span>Si</span>
      </label>
    </p>
    <p>
      <label>
        <input name="group1" type="radio" onChange={update}  />
        <span>No</span>
      </label>
    </p> 
      </div>

     {/*} <Stats step={4} {...props} nextStep={validate} />*/}
    </div>
  )
};