import React, { useEffect, useState } from "react";
import classnames from "classnames";
import Validator from "validator";
import { Stats } from "./Stats";
import { getCountries, getStates, getCities } from "../../actions/countriesActions";
import materialize from "materialize-css";
import { getZipCode } from "../../actions/zipcodeActions";


export const CompanyInformation = (props) => {

  const [companyStartOperationsDate, setcompanyStartOperationsDate] = useState("");
  
  const [errorNombreEmpresa, setErrorNombreEmpresa] = useState('')
  const [errorRFC, setErrorRFC] = useState('');
  const [errorPrincipalActividad, setErrorPrincipalActividad] = useState('');
  const [errorCompanyStartOperationsDate, setCompanyStartOperationsDate] = useState('');
  const [errorCompanyExportCountries, setCompanyErrorExportCountries] = useState('');
  const [errorCompanyImportCountries, setCompanyErrorImportCountries] = useState('');
  const [errorCargo, setErrorCargo] = useState('');
  const [errorCodigoPostalEmpresa, setErrorCodigoPostalEmpresa] = useState('');
  const [errorTelefono, setErrorTelefono] = useState('');
  const [errorPais, setErrorPais] = useState('');
  const [errorEstado, setErrorEstado] = useState('');
  const [errorCiudadEmpresa, setErrorCiudadEmpresa] = useState('');

  const errorRazonSocial = '';
  //const errorColonia = '';
  const errorDomicilio = '';
  //const errorDomicilioNumero = '';
  const errorwwwEmpresa = '';
  const errorRedesSociales = '';

  const populateStates = (country) => {
    let states = [];
    getStates(country)
      .then(response => {
        states = response.data[0].states.map((state) => {
          return <option key={state} value={state}>{state}</option>
        });

        props.update("states", states);
        //materialize.AutoInit();
        //materialize.updateTextFields();

        //document.addEventListener('DOMContentLoaded', function() {
          var elems = document.querySelectorAll('select');
          var instances = materialize.FormSelect.init(elems);
        //});
      

      })
      .catch(err => console.log(err));
  };

  const populateCities = (country, state) => {
    let cities = [];
    getCities(country, state)
      .then(response => {
        console.log(response);
        cities = response.data.map((city) => {
          return <option key={city} value={city}>{city}</option>
        });

        props.update("cities", cities);

        //document.addEventListener('DOMContentLoaded', function() {
          var elems = document.querySelectorAll('select');
          var instances = materialize.FormSelect.init(elems);
        //});

        
        //materialize.AutoInit();
        //materialize.updateTextFields();

        
    

      })
      .catch(err => console.log(err));
  };


  useEffect(() => {


    document.addEventListener('DOMContentLoaded', function () {
      var start = document.querySelectorAll('.datepicker');
      materialize.Datepicker.init(start, {
          format: "dd/mm/yyyy",
          autoClose: true
      });

      populateStates("MX");

      
  });

    

    
  }, []);


  const update = (e) => {

    props.update(e.target.id, e.target.value);

    if (e.target.id === "pais") {
      populateStates(e.target.value);
      props.update("cities",[]);
      props.update("ciudadEmpresa","");
    }

    if ((e.target.id === "estado") && (props.form.pais === "MX" || props.form.pais === "US")) {
      populateCities(props.form.pais, e.target.value);      
    }

  };


  const validate = async () => {
    let valid = true;
    let language = localStorage.getItem("exmi_language");

    // Errors clean up
    /*
    setErrorNombreEmpresa("");
    setErrorCargo("");
    setErrorCodigoPostalEmpresa("");
    setErrorTelefono("");
    setErrorPais("");
    setErrorEstado("");
    setErrorCiudadEmpresa("");


    if (language === "es") {
      // Nombre Empresa
      if (Validator.isEmpty(props.form.nombreEmpresa)) {
        setErrorNombreEmpresa("Nombre de Empresa es requerido");
        valid = false;
      }

      // Cargo
      if (Validator.isEmpty(props.form.cargo)) {
        setErrorCargo("Posicion en la empresa es requerido");
        valid = false;
      }

      if (!Validator.isEmpty(props.form.telefono)) {
        if (Validator.isInt(props.form.telefono)) {
          if (!Validator.isLength(props.form.telefono, { min: 10, max: 10 })) {
            setErrorTelefono("Numero de Telefono Invalido, numero esperado a 10 digitos");
            valid = false;
          }
        }
        else {
          setErrorTelefono("Numero de Telefono Invalido, numero esperado a 10 digitos");
          valid = false;
        }
      }

      // Código Postal
      if (Validator.isEmpty(props.form.codigoPostalEmpresa)) {
        setErrorCodigoPostalEmpresa("Código Postal de la empresa es requerido");
        valid = false;
      }
      else if (props.form.pais === "MX" || props.form.pais === "US") {
        if (!Validator.isInt(props.form.codigoPostalEmpresa)) {
          setErrorCodigoPostalEmpresa("Código Postal de la empresa es invalido");
          valid = false;
        }
        else {
          await getZipCode(props.form.pais, props.form.codigoPostalEmpresa)
            .then(response => {

              if (response.data === null) {
                setErrorCodigoPostalEmpresa("Código Postal de la empresa es invalido");
                valid = false;
              }
            });
        }
      }   

      // Pais
      if (Validator.isEmpty(props.form.pais)) {
        setErrorPais("Pais es requerido");
        valid = false;
      }

      // Estado
      if (Validator.isEmpty(props.form.estado)) {
        setErrorEstado("Estado es requerido");
        valid = false;
      }

      // Ciudad Empresa
      if (Validator.isEmpty(props.form.ciudadEmpresa)) {
        setErrorCiudadEmpresa("Ciudad de la Empresa es requerida");
        valid = false;
      }
    }
    else {
      // Nombre Empresa
      if (Validator.isEmpty(props.form.nombreEmpresa)) {
        setErrorNombreEmpresa("Company Name is Required");
        valid = false;
      }

      // Cargo
      if (Validator.isEmpty(props.form.cargo)) {
        setErrorCargo("Company Position is Required");
        valid = false;
      }

      // Telefono
      if (!Validator.isEmpty(props.form.telefono)) {
        if (Validator.isInt(props.form.telefono)) {
          if (!Validator.isLength(props.form.telefono, { min: 10, max: 10 })) {
            setErrorTelefono("Phone number Invalid, expected number to 10 digits");
            valid = false;
          }
        }
        else {
          setErrorTelefono("Phone number Invalid, expected number to 10 digits");
          valid = false;
        }
      }

      // Código Postal
      if (Validator.isEmpty(props.form.codigoPostalEmpresa)) {
        setErrorCodigoPostalEmpresa("ZipCode Company is required");
        valid = false;
      }
      else if (props.form.pais === "MX" || props.form.pais === "US") {
        if (!Validator.isInt(props.form.codigoPostalEmpresa)) {
          setErrorCodigoPostalEmpresa("ZipCode Company is invalid");
          valid = false;
        }
        else {
          await getZipCode(props.form.pais, props.form.codigoPostalEmpresa)
            .then(response => {

              if (response.data === null) {
                setErrorCodigoPostalEmpresa("ZipCode Company is invalid");
                valid = false;
              }
            });
        }
      }      

      // Pais
      if (Validator.isEmpty(props.form.pais)) {
        setErrorPais("Country is Required");
        valid = false;
      }

      // Estado
      if (Validator.isEmpty(props.form.estado)) {
        setErrorEstado("State is Required");
        valid = false;
      }

      // Ciudad Empresa
      if (Validator.isEmpty(props.form.ciudadEmpresa)) {
        setErrorCiudadEmpresa("Company City is Required");
        valid = false;
      }
    }
    */

    if (valid)
      props.nextStep();
  };

  const handleChange = (e) => {
    this.setState({
        [e.target.id]: e.target.value
    })
}


  return (
    <div>
      <div className="col s12" style={{ paddingLeft: "11.250px" }}>
        <h4>
          <b>{props.locale.dashboard_comprador_label_companyinformation}</b>
        </h4>
      </div>
      <div className="input-field col s12 m6 l6">
        <input
          onChange={update}
          value={props.form.rfc}
          error={errorRFC}
          id="rfc"
          type="text"
           className={classnames("font-face-msb", {
            invalid: errorRFC
          })}
        />
        <label htmlFor="rfc">{props.locale.dashboard_comprador_label_companyRFC}</label>
        <span className="red-text">{errorRFC}</span>
      </div> 
      <div className="input-field col s12 m6 l6">
        <input
          onChange={update}
          value={props.form.razonSocial}
          error={errorRazonSocial}
          id="razonSocial"
          type="text"
           className={classnames("font-face-msb", {
            invalid: errorRazonSocial
          })}
        />
        <label htmlFor="razonSocial">{props.locale.dashboard_comprador_label_businessname}</label>
        <span className="red-text">{errorRazonSocial}</span>
      </div>          
      <div className="input-field col s12">
        <input
          onChange={update}
          value={props.form.nombreEmpresa}
          error={errorNombreEmpresa}
          id="nombreEmpresa"
          type="text"
           className={classnames("font-face-msb", {
            invalid: errorNombreEmpresa
          })}
        />
        <label htmlFor="nombreEmpresa">{props.locale.dashboard_comprador_label_nameofcompany}</label>
        <span className="red-text">{errorNombreEmpresa}</span>
      </div>

      <div className="input-field col s12 m6 l6">
        <input
          onChange={update}
          value={props.form.domicilio}
          error={errorDomicilio}
          id="domicilio"
          type="text"
           className={classnames("font-face-msb", {
            invalid: errorDomicilio
          })}
        />
        <label htmlFor="domicilio">{props.locale.dashboard_comprador_label_Street}</label>
        <span className="red-text">{errorDomicilio}</span>
      </div>

      <div className="input-field col s12 m6 l6">
        <input
          onChange={update}
          value={props.form.codigoPostalEmpresa}
          error={errorCodigoPostalEmpresa}
          id="codigoPostalEmpresa"
          type="text"
           className={classnames("font-face-msb", {
            invalid: errorCodigoPostalEmpresa
          })}
          maxLength="10"
          onKeyPress={(event) => { if ((props.form.pais === "MX" || props.form.pais === "US") && !/[0-9]/.test(event.key)) { event.preventDefault(); } }}      
        />
        <label htmlFor="codigoPostalEmpresa">{props.locale.dashboard_comprador_label_zipcode}</label>
        <span className="red-text">{errorCodigoPostalEmpresa}</span>
      </div>


      <div className="input-field col s12 m6 l6">
        <select value={props.form.estado} onChange={update} id="estado">
          <option value="" >
            {props.locale.dashboard_comprador_label_state_select}
          </option>
          {props.form.states}
        </select>
        <label htmlFor="estado">{props.locale.dashboard_comprador_label_state}</label>
        <span className="red-text">{errorEstado}</span>
      </div>

      <div className="input-field col s12 m6 l6">
            <select value={props.form.ciudadEmpresa} onChange={update} id="ciudadEmpresa">
              <option value="" >
                {props.locale.dashboard_comprador_label_city_select}
              </option>
              {props.form.cities}
            </select>
            <label htmlFor="ciudad">{props.locale.dashboard_comprador_label_city}</label>
            <span className="red-text">{errorCiudadEmpresa}</span>
          </div>



      <div className="input-field col s12 m6 l6">
        <input
          onChange={update}
          value={props.form.principalActividad}
          error={errorPrincipalActividad}
          id="principalActividad"
          type="text"
           className={classnames("font-face-msb", {
            invalid: errorPrincipalActividad
          })}
        />
        <label htmlFor="principalActividad">{props.locale.dashboard_comprador_label_business}</label>
        <span className="red-text">{errorPrincipalActividad}</span>
      </div>

      
      <div className="input-field col s12 m6 l6">
        <input
          onChange={update}
          class="datepicker"
          value={props.form.companyStartOperationsDate}
          error={errorCompanyStartOperationsDate}
          id="companyStartOperationsDate"
          type="text"
        />
        <label htmlFor="companyStartOperationsDate">{props.locale.dashboard_comprador_label_company_start_operation_date}</label>
        <span className="red-text">{errorCompanyStartOperationsDate}</span>
      </div>

        
      <div className="input-field col s6 m6 l6">{props.locale.dashboard_comprador_label_company_export}</div>
                    <div className="input-field col s2">
                        <div className="switch">
                            <label>
                                <input type="checkbox" id="companyExport" onChange={update} checked={props.form.companyExport} />
                                <span className="lever"></span>
                            </label>
                        </div>
      </div>
      <div className="input-field col s12">
        <input
          onChange={update}
          value={props.form.companyExportCountries}
          error={errorCompanyExportCountries}
          id="companyExportCountries"
          type="text"
           className={classnames("font-face-msb", {
            invalid: errorCompanyExportCountries
          })}
        />
        <label htmlFor="companyExportCountries"> A que país?</label>
        <span className="red-text">{errorCompanyExportCountries}</span>
      </div>


      <div className="input-field col s6 m6 l6">{props.locale.dashboard_comprador_label_company_import}</div>
                    <div className="input-field col s4">
                        <div className="switch">
                            <label>
                                <input type="checkbox" id="companyImport" onChange={update} checked={props.form.companyImport} />
                                <span className="lever"></span>
                            </label>
                        </div>
      </div>

      <div className="input-field col s12">
        <input
          onChange={update}
          value={props.form.companyImportCountries}
          error={errorCompanyImportCountries}
          id="companyImportCountries"
          type="text"
           className={classnames("font-face-msb", {
            invalid: errorCompanyImportCountries
          })}
        />
        <label htmlFor="companyImportCountries">Pais</label>
        <span className="red-text">{errorCompanyImportCountries}</span>
      </div>
        
            {/*<Stats step={2} {...props} nextStep={validate} />*/}
    </div>
  )
};