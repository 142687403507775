import React, { Component } from "react";
import materialize from "materialize-css";
import 'react-flags-select/css/react-flags-select.css';
import mx from '../resources/mx.svg';
import us from '../resources/us.svg';
import bannerEs from '../resources/BannerRegistroES.jpg'
import bannerEn from '../resources/BannerRegistroEN.jpg'
import bannerMobileEs from '../resources/BannerRegistroMobileES.jpg'
import bannerMobileEn from '../resources/BannerRegistroMobileEN.jpg'

import detectBrowserLanguage from 'detect-browser-language'

class Header extends Component {


    constructor() {
        super();
        this.SetLanguage();
        this.locale = localStorage.getItem("exmi_language");
    }

    componentDidMount() {
        materialize.AutoInit();
    }

    getUrlVars() {
        var vars = {};
        window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
            vars[key] = value;
        });
        return vars;
    }

    SetLanguage() {

        var lang = this.getUrlVars()["lang"];
        if (lang !== undefined && (lang === "en" || lang === "es" || lang === "EN" || lang === "ES")) {
            localStorage.setItem("exmi_language", lang.toLowerCase());
        }
        else {
            const languagefromLocalStorage = localStorage.getItem("exmi_language");

            if (languagefromLocalStorage === null) {
                const countryCodeFromHeaders = detectBrowserLanguage();
                if (countryCodeFromHeaders.includes("en")) {
                    localStorage.setItem("exmi_language", "en")
                }
                else {
                    localStorage.setItem("exmi_language", "es")
                }
            }
        }
    }



    handleClickImage(e) {
        e.preventDefault();
        let countrycode = e.target.alt;
        localStorage.setItem("exmi_language", countrycode);
        var url = window.location.href.split('?')[0];
        window.location.href = url;
    }

    handleClickAnchor(e) {
        e.preventDefault();
        let countrycode = e.target.children[0].alt;
        localStorage.setItem("exmi_language", countrycode);
        var url = window.location.href.split('?')[0];
        window.location.href = url;
    }


    render() {
        return (
            <>
                <div>
                    {this.locale === "es" ?
                        <>
                            <div class="col s12 m12 l4 noppading imageNotMargings imagen">
                                <img className="responsive-img imagen desktopShow" alt="Banner" src={bannerEs} />
                                <img className="responsive-img imageNotMargings mobileShow" alt="Banner" src={bannerMobileEs} />
                            </div>
                            <div class="col s12 m12 l8">
                                <div id="block_container" class="right paddingtopflags">
                                    {/*
                                    <div className="padded">
                                        <a href="#!" onClick={e => this.handleClickAnchor(e)}><img id="us" title="English" alt="en" className="image-flag" src={us} onClick={e => this.handleClickImage(e)} /></a>
                                    </div>
                                    */}
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div class="col s12 m12 l4 noppading">
                                <img className="responsive-img imagen imageNotMargings desktopShow" alt="Banner" src={bannerEn} />
                                <img className="responsive-img imageNotMargings mobileShow" alt="Banner" src={bannerMobileEn} />
                            </div>
                            <div class="col s12 m12 l8">
                                <div id="block_container" class="right paddingtopflags">
                                    <div className="padded">
                                        {/*
                                        <a href="#!" onClick={e => this.handleClickAnchor(e)}><img id="mx" title="Español" alt="es" className="image-flag" src={mx} onClick={e => this.handleClickImage(e)} /></a>
                                        */}
                                    </div>
                                </div>
                            </div>
                        </>
                    }

                </div>
            </>
        );
    }
}

export default Header;