import React, { Component } from "react";
import bannerEn from '../resources/cintillopatrocinadores.jpg'



class Footer extends Component {


    constructor() {
        super();
    }


    render() {
        return (
            <div class="col s12 m12 l8">
                <img className="responsive-img imageNotMargings" alt="Banner" src={bannerEn} />
            </div>
        );
    }
}

export default Footer;