import React, { useState, useEffect } from "react";
import classnames from "classnames";
import Validator from "validator";
import { Stats } from "./Stats"
import bannerEn from '../resources/cintillopatrocinadores.jpg'

export const VisitanteInformation = (props) => {

    const [errorEmail, setErrorEmail] = useState('');
    const [errorNombre, setErrorNombre] = useState('');
    const [errorApellido, setErrorApellido] = useState('');
    const [errorTelefono, setErrorTelefono] = useState('');
    const [errorNombreEmpresa, setErrorNombreEmpresa] = useState('');




    const update = (e) => {
        props.update(e.target.id, e.target.value);
    };


    const submit = async () => {

        let valid = true;
        let language = localStorage.getItem("exmi_language");

        // Errors clean up
        setErrorEmail("");
        setErrorNombre("");
        setErrorApellido("");
        setErrorTelefono("");
        setErrorNombreEmpresa("");


          // Email checks
          if (Validator.isEmpty(props.form.email)) {
            setErrorEmail("Email es requerido");
            valid = false;
        } else if (!Validator.isEmail(props.form.email)) {
            setErrorEmail("Email es invalido");
            valid = false;
        }

        // Nombres
        if (Validator.isEmpty(props.form.nombres)) {
            setErrorNombre("Nombre es requerido");
            valid = false;
        }

        // Apellidos
        if (Validator.isEmpty(props.form.apellidos)) {
            setErrorApellido("Apellido es requerido");
            valid = false;
        }

        // Nombre Empresa
        if (Validator.isEmpty(props.form.nombreEmpresa)) {
            setErrorNombreEmpresa("El nombre de empresa es requerido");
            valid = false;
        }        

        if (!Validator.isEmpty(props.form.telefono)) {
            if (Validator.isInt(props.form.telefono)) {
              if (!Validator.isLength(props.form.telefono, { min: 10, max: 10 })) {
                setErrorTelefono("Numero de Telefono Invalido, numero esperado a 10 digitos");
                valid = false;
              }
            }
            else {
              setErrorTelefono("Numero de Telefono Invalido, numero esperado a 10 digitos");
              valid = false;
            }
          }
        else {
          setErrorTelefono("Numero de Telefono de requerido");
          valid = false;
        }
    

        if (valid)
            props.submit();
    };

    return (
        <div>
           <div class="col s12 m12 l12">
               <h6>
               Para el comité organizador es un placer que nos pueda acompañar a la ceremonia de premiación, que se llevara a cabo el día 9 de mayo de 2024 a las 20:00 hrs. En Expo Guadalajara.
               </h6>
               <br></br>
               <h6>
               El costo del boleto es $1,500.00 +IVA. 
               </h6>
               <br></br>
               <h6 className="font-face-mb">
               Favor de colocar tus datos correctos, recuerda que tu boleto DIGITAL es personalizado e intransferible.  
               </h6>
               <br />
               </div>
        <br></br>
        <br></br>
        <div>
            <div className="input-field col s12">
                <input
                    disabled
                    placeholder=""
                    onChange={update}
                    value={props.form.email}
                    error={errorEmail}
                    id="email"
                    type="email"
                     className={classnames("font-face-msb", {
                        invalid: errorEmail
                    })}
                />
                <label htmlFor="email" className="active">{props.locale.dashboard_comprador_label_mail}</label>
                <span className="red-text">{errorEmail}</span>
            </div>
            <div className="input-field col s12 m6 l6">
                <input
                    onChange={update}
                    value={props.form.nombres}
                    error={errorNombre}
                    id="nombres"
                    type="text"
                     className={classnames("font-face-msb", {
                        invalid: errorNombre
                    })}
                />
                <label htmlFor="nombres">{props.locale.dashboard_comprador_label_name}</label>
                <span className="red-text">{errorNombre}</span>
            </div>
            <div className="input-field col s12 m6 l6">
                <input
                    onChange={update}
                    value={props.form.apellidos}
                    error={errorApellido}
                    id="apellidos"
                    type="text"
                     className={classnames("font-face-msb", {
                        invalid: errorApellido
                    })}
                />
                <label htmlFor="apellidos">{props.locale.dashboard_comprador_label_lastname}</label>
                <span className="red-text">{errorApellido}</span>
            </div>

            <div className="input-field col s12 m6 l6">
              <input
                onChange={update}
                value={props.form.nombreEmpresa}
                error={errorNombreEmpresa}
                id="nombreEmpresa"
                type="text"
                 className={classnames("font-face-msb", {
                  invalid: errorNombreEmpresa
                })}
              />
              <label htmlFor="nombreEmpresa">Nombre Comercial de la empresa</label>
              <span className="red-text">{errorNombreEmpresa}</span>
            </div>

            <div className="input-field col s12 m6 l6">
        <input
          onChange={update}
          value={props.form.telefono}
          error={errorTelefono}
          id="telefono"
          type="text"
           className={classnames("font-face-msb", {
            invalid: errorTelefono
          })}
          maxLength="10"
          onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}          
        />
        <label htmlFor="telefono">{props.locale.dashboard_comprador_label_phone}</label>
        <span className="red-text">{errorTelefono}</span>
      </div>
      </div>

      <div class="col s12 m12 l12">
        <p className="grey-text text-darken-2">

        Realiza tu pago a: 
        </p>
        <p className="grey-text text-darken-3">
        Cámara de la Industria Mueblera del Estado de Jalisco.<br></br>    
        No. Tarjeta: 5161 0200 0398 0770<br></br>   
        Banbajio. 
        </p>
        <p className="grey-text text-darken-2">
        Manda tu comprobante de pago al correo: <a href="mailto:afiliaciones@cimejal.com.mx">afiliaciones@cimejal.com.mx</a> ó al  whatsapp <a href="https://api.whatsapp.com/send?phone=3328355074&text=Hola!">3328355074</a> 
        </p>
        </div>
        <div>
            <Stats step={2} {...props} nextStep={submit} locale={props.locale} disableSubmit={props.disableSubmit} />
            </div>
            <br></br>
            <br></br>
            <div>
            <img className="responsive-img imageNotMargingsFooter" alt="Banner" src={bannerEn} />
           </div>
        </div>
        

    );
};
